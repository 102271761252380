import React from "react";
import "./Hero.css";
import compass from "./../../compass.png"


export const Hero = () => {
  return (
    <div className="Hero" id="who">
      <div className="info">
        <h2>¿QUIÉNES SOMOS?</h2>
        <p>
        Somos una Empresa argentina perteneciente a el <a href="http://gpe.group/"> GPE</a> con el claro objetivo de apoyar el crecimiento integral de nuestros clientes a través de la consultaría y capacitación de sus equipos de trabajo, mediante programas que aportan herramientas útiles e innovadoras para el desempeño de sus actividades cotidianas.
        </p>
      </div>
      <div className="img">
      <img src={compass}></img>
      </div>
    </div>
  );
};
