import React from "react";
import "./Contact.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

export const Contact = () => {
  return (
    <div className="Contact" id="contacto">
      <h3>Contacto</h3>
      <div className="contact-details">
        <div className="lista">
          <p>
            <FaPhoneAlt /> +54 9 11 6767-1100
          </p>
          <p>
            <FaPhoneAlt /> +54 9 11 3011-1222
          </p>
          <p>
            <IoMdMail /> info@riondo.com.ar
          </p>
        </div>
        <div className="location">
          <p>
            {" "}
            Encontranos en <FaLocationDot /> <p>Av. del Carmen 750</p>
          </p>
          <iframe
            className="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1567469032498!2d-58.39271282374245!3d-34.60019767295563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac0a192a159%3A0xbe8970353ce44e85!2sDel%20Carmen%20750%2C%20C1019%20San%20Nicolas%2C%20Buenos%20Aires!5e0!3m2!1sen!2sar!4v1708367825735!5m2!1sen!2sar"
            width="300"
            height="150"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
