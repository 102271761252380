import React, { useState } from "react";
import "./services.css";

import rompe from "./../../rompe.png"
export const Reorg = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container" id="reorganización">
      <div className="text">
        <h3>Consultoría en Reorganización de Empresas</h3>
        <p>
          Nuestro servicio de consultaria en reorganización de empresas está
          diseñado para ayudar a las organizaciones a optimizar su estructura,
          procesos y recursos, con el objetivo de mejorar la eficiencia, la
          rentabilidad y la capacidad de adaptación a los desafios del entorno
          empresarial. Nuestro equipo de expertos en reorganización empresarial
          trabaja en estrecha colaboración con las empresas para identificar
          áreas de mejora y desarroilar estrategias personalizadas para alcanzar
          sus objetivos
        </p>
        <p className={`more-content ${showMore ? "show" : ""}`}>
          <h4>Características principales</h4>
          <ul>
            <li>
              Diagnóstico Empresarial: Realizamos un análisis integral de la
              situación actual de la empresa, identificando problemas y
              oportunidades.
            </li>
            <li>
              Diseño de Estrategias: Desarrollamos estrategias a medida para
              reorganizar la empresa, incluyendo cambios en la estructura
              organizativa, procesos de negocio y gestión de recursos.
            </li>
            <li>
              Implantación y Seguimiento: Trabajamos en estrecha colaboración
              con el equipo de dirección para implementar las estrategias
              propuestas y seguirlas de cerca para garantizar su éxito.
            </li>
            <li>
              Optimización de Recursos: Ayudamos a maximizar la eficiencia
              operativa, reducir costos innecesarios y mejorar la asignación de
              recursos.
            </li>
            <li>
              Gestión del Cambio: Apoyamos a la empresa en la gestión del cambio
              organizativo, asegurando una transición ordenada y minimizando la
              resistencia al cambio.
            </li>
            <li>
              Mejora de la Rentabilidad: Nuestras estrategias están diseñadas
              para aumentar la rentabilidad a largo plazo y asegurar la
              sostenibilidad del negocio
            </li>
            <li>
              Clave de beneficios:
              <ul>
                <li>Aumento de la eficiencia operativa</li>
                <li>Mayor competitividad en el mercado</li>
                <li>Mejora en la toma de desiciones estratégicas</li>
                <li>Optimización de la estructura organizativa</li>
                <li>
                  Mayor rentabilidad y valor para los accionistas
                  <p>
                    Nuestro servicio de consultaría en reorganización de
                    empresas se adapta a las necesidades específicas de cada
                    cliente y se enfoca en lograr resultados medibles y
                    sostenibles. Si su empresa se enfrenta a desafios
                    organizativos o busca mejorar su rendimiento empresarial,
                    estamos aquí para ayudar.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <div className="more">
          <button onClick={toggleMore}>
            {showMore ? "Cerrar" : "Leer más"}
          </button>
        </div>
      </div>
      <div>
        <img src={rompe} className="rompe"></img>
      </div>
    </div>
  );
};
