import "./App.css";
import { Contact } from "./Components/Contact/Contact";
import { Hero } from "./Components/Hero/Hero";
import NavBar from "./Components/NavBar/NavBar";
import { Desarrollo } from "./Components/Servicios/Desarrollo";
import { Inversiones } from "./Components/Servicios/Inversiones";
import { Reorg } from "./Components/Servicios/Reorg";
import banner from "./banner.png";

function App() {
  return (
    <div className="App">
      <NavBar />
      <body>
        <div className="header">
          <img src={banner}></img>
        </div>
        <Hero id="who" />
        <div className="servicios">
          <h2>SERVICIOS</h2>
          <Reorg id="reorganización" />
          <Desarrollo id="desarrollo" />
          <Inversiones id="inversiones" />
        </div>
      </body>
      <Contact id="contacto" />
    </div>
  );
}

export default App;
