import React, { useState } from "react";
import "./services.css";
import graph from "./../../graph.png";

export const Inversiones = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container" id="inversiones">
      <div className="text">
        <h3>Servicios de Consultoría de inversiones en el extranjero</h3>
        <p>
          Asesoramiento especializado para individuos o empresas que buscan
          realizar inversiones fuera de Argentina. Tenemos presencia en Uruguay,
          Paraguay, Brasil y España.
        </p>
        <p className={`more-content ${showMore ? "show" : ""}`}>
          Realizamos una evaluación de las condiciones económicas y políticas en
          el país de destino, analizando las tendencias del mercace y
          oportunidades de inversión.
          <p>
            Proponemos y recomendamos una diversificación de la cartera de
            inversiones a nivel internacional para reducir riesgos
          </p>
          <p>
            {" "}
            Asesoramos sobre las regulaciones fiscales y legales en el país de
            destino de las inversiones, generando estrategias para optimizar la
            estructura fiscal de dichas inversiones extranjeras.
          </p>
          <p>
            Asimismo identificamos y evaluamos los riesgos asociados con las
            inversiones en el extranjero, desarroliando estrategias para mitigar
            estos riesgos, asesorando en la elección de activos específicos,
            como bonos, acciones, bienes raices u otros existentes en el mercado
            global.
          </p>
        </p>
        <div className="more">
          <button onClick={toggleMore}>
            {showMore ? "Cerrar" : "Leer más"}
          </button>
        </div>
      </div>
      <div>
        <img src={graph} className="graph"></img>
      </div>
    </div>
  );
};
