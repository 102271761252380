import React, { useState } from "react";
import "./services.css";
import { FcWorkflow } from "react-icons/fc";

export const Desarrollo = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container" id="desarrollo">
      <div className="text">
        <h3>
          Servicios de análisis, desarrollo y mejora de sistemas de información
        </h3>
        <p>

          
          Aquí nos enfocamos en evaluar, crear y optimizar sistemas de
          información en una organización, asesorando en la planeación y diseño
          de la mejor solucion para modernizar y mejorar un proceso existente en
          su compañia, generando reducción de costos y de tiempos muertos.
        </p>
        <p className={`more-content ${showMore ? "show" : ""}`}>
          <p>Siguiendo los pasos que se describen:</p>
          <ul>
            <li>
              Análisis
              <ul>
                <li>
                  Evaluación de necesidades. Comprender los requisitos y
                  necesidades de la organización en términos de sistemas de
                  información.
                </li>
                <li>
                  Análisis de procesos: Examinar y comprender los procesos
                  existentes para identificar áreas de mejora.
                </li>
              </ul>
            </li>
            <li>
              Desarrollo
              <ul>
                <li>
                  Diseño de sistemas: Crear planos y diseños para nuevos
                  sistemas de información o mejoras en los existentes.
                </li>
                <li>
                  Programación e implementación: Desarrollar y poner en práctica
                  los sistemas de información de acuerdo con los diseños
                  establecidos.
                </li>
              </ul>
            </li>
            <li>
              Mejora
              <ul>
                <li>
                  Optimización de procesos: Identificar y aplicar mejoras en los
                  procesos de negocio mediante el uso de la tecnología de la
                  información.
                </li>
                <li>
                  Actualizaciones y mantenimiento: Mantener los sistemas
                  existentes al día, corrigiendo errores, aplicando parches de
                  seguridad y realizando actualizaciones.
                  <p>
                    Este servicio es crucial para garantizar que los sistemas de
                    información de una organización sean eficientes, seguros y
                    cumplan con las necesidades cambiantes del negocio. Además,
                    ayuda a aprovechar nuevas tecnologías y prácticas para
                    mejorar continuamente la eficacia de los sistemas
                    informativos
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <div className="more">
          <button onClick={toggleMore}>
            {showMore ? "Cerrar" : "Leer más"}
          </button>
        </div>
      </div>
      <div>
      
      <FcWorkflow className="icon" />

      </div>
    </div>
  );
};
