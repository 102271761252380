import React, { useEffect, useState } from "react";
import logo from "../../logo.jpeg";
import "./Navbar.css";

const NavBar = () => {
  const [shrink, setShrink] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={`navbar ${shrink ? "shrink" : ""}`}>
      <div className="navbar__container">
        <a href="#home" className="navbar__brand">
          <img src={logo} className="navbar__logo" alt="Logo" />
        </a>
        <div className={`navbar__links ${menuOpen ? "show" : ""}`}>
          <a href="#who" className="navbar__link">
            ¿Quiénes Somos?
          </a>
          <div className="navbar__dropdown">
            <button className="navbar__dropdown-btn">Servicios ↓</button>
            <div className="navbar__dropdown-content">
              <a href="#reorganización">Reorganización Empresarial</a>
              <a href="#desarrollo">Análisis y desarrolo</a>
              <a href="#inversiones">Inversiones en el extranjero</a>
            </div>
          </div>
          <a href="#contacto" className="navbar__link">
            Contacto
          </a>
        </div>
        <div className="navbar__menu-toggle" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
